import { clsx } from 'clsx';
import { throttle } from 'rambdax';
import React, { forwardRef, useMemo } from 'react';
import { DismissButton, OverlayContainer, mergeProps, useDialog, useModal, useOverlay, useTooltip } from 'react-aria';

import { useClientEffect } from '@ping/hooks';
import { domEvent } from '@ping/utils';

import { TooltipContext } from '../tooltip.context';

import style from './style.module.scss';

import type IReactAria from 'react-aria';

interface ITooltipContentProps extends IReactAria.AriaOverlayProps {
  className?: string;
  style?: any;
  boundaryRef: React.MutableRefObject<HTMLElement>;
  children: React.ReactNode;
  placement: 'top' | 'bottom' | 'left' | 'right' | 'center';
  /** Handler that is called when the overlay should close. */
  onClose?: (immediate?: boolean) => void;
}

const TooltipContent = forwardRef((props: ITooltipContentProps, ref: React.RefObject<HTMLDivElement>) => {
  const { children, className, isOpen, onClose, boundaryRef, style: positionStyle, placement, ...restProps } = props;

  // Handle interacting outside the dialog and pressing
  // the Escape key to close the modal.
  const { overlayProps } = useOverlay({ onClose, isOpen, isDismissable: true }, ref);

  // Hide content outside the modal from screen readers.
  const { modalProps } = useModal();

  // Get props for the dialog and its title
  const { dialogProps } = useDialog({}, ref);

  // Set the popover content's inline size to the inline size of its boundary element
  // Update the popover content's position on page resizing
  const resizeObserver = useMemo(() => {
    return new ResizeObserver(entries => {
      for (const entry of entries) {
        const { width, left, right } = entry.target.getBoundingClientRect();
        if (!ref.current) return;

        ref.current.style.inlineSize = width + 'px';
        if (placement?.includes('left')) {
          ref.current.style.left = left + 'px';
        }
        if (placement?.includes('right')) {
          ref.current.style.right = right + 'px';
        }
      }
    });
  }, []);

  useClientEffect(() => {
    if (boundaryRef?.current) {
      resizeObserver.observe(boundaryRef.current);
    }

    return () => {
      if (!boundaryRef?.current) return;

      resizeObserver.unobserve(boundaryRef.current);
      resizeObserver.disconnect();
    };
  }, []);

  useClientEffect(() => {
    const detachScrollListener = domEvent.attach(
      window,
      'scroll',
      throttle(() => {
        onClose(true);
        if (boundaryRef?.current) {
          resizeObserver.observe(boundaryRef.current);
        }
      }, 500),
      { capture: true }
    );

    return () => {
      detachScrollListener();

      if (!boundaryRef?.current) return;
      resizeObserver.unobserve(boundaryRef.current);
      resizeObserver.disconnect();
    };
  }, [isOpen]);

  return (
    <>
      {/* NOTE: "style/positionStyle" comes from "react-aria" to adjust position of popover content */}
      <div
        className={clsx(style['tooltip-content'], className)}
        data-placement={placement}
        data-part={props['data-part']}
        style={positionStyle}
        ref={ref}
        {...mergeProps(overlayProps, dialogProps, restProps, modalProps)}
      >
        {children}
        <DismissButton onDismiss={onClose} />
      </div>
    </>
  );
});

interface ITooltipPanelProps extends ICustomizable {
  /**
   * The option to mount and unmount the tooltip on DOM
   * @default false
   */
  unmount?: boolean;
  children: React.ReactNode;
}

export const TooltipPanel = (props: ITooltipPanelProps) => {
  const ctx = React.useContext(TooltipContext);
  const { tooltipProps } = useTooltip(ctx.tooltipProps, ctx.state);

  if (!ctx.state.isOpen && props.unmount) {
    return <></>;
  }

  return (
    <OverlayContainer>
      <TooltipContent
        {...mergeProps(props, tooltipProps, ctx.overlayProps)}
        data-placement={ctx.placement}
        aria-hidden={!ctx.state.isOpen}
        ref={ctx.overlayRef}
        boundaryRef={ctx.triggerRef}
        isOpen={ctx.state.isOpen}
        onClose={ctx.state.close}
        className={props.className}
        placement={ctx.placement}
      >
        {props.children}
      </TooltipContent>
    </OverlayContainer>
  );
};
