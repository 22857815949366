// @ts-nocheck

import { isNil } from 'rambdax';

const cancelEvent = (event: Event | React.SyntheticEvent) => {
  if (isNil(event)) {
    return;
  }

  try {
    event = event.originalEvent || event;
    event.stopPropagation();
    event.preventDefault();
    event.returnValue = false;
    event.cancelBubble = true;

    // eslint-disable-next-line no-empty
  } catch (err) {}
};

/**
 * Attaches an event listener to the given node.
 *
 * @param {Element | Window | Document} node - The DOM element to attach the event to
 * @param {string} type - The type of event to listen for
 * @param {EventListenerOptions} options - The event listener options
 * @param {EventListener} listener - The event listener function
 */
const attachEvent = (
  node: Element | Window | Document,
  type: string,
  listener: EventListener,
  options?: EventListenerOptions
) => {
  node.addEventListener(type, listener, options);
  return () => node.removeEventListener(type, listener, options);
};

export const domEvent = Object.freeze({
  cancel: cancelEvent,
  attach: attachEvent,
});
