export enum ETransferStatus {
  ALL = 'All',
  WORKING = 'Working',
  PENDING = 'Pending',
  PENDINGREVIEW = 'PendingReview',
  COMPLETED = 'Completed',
  FAILED = 'Failed',
}

export const TRANSFER_STATUS_LABELS_MAP: Record<ETransferStatus, string> = {
  [ETransferStatus.ALL]: 'All',
  [ETransferStatus.WORKING]: 'Working',
  [ETransferStatus.PENDING]: 'Pending',
  [ETransferStatus.PENDINGREVIEW]: 'Pending for review',
  [ETransferStatus.COMPLETED]: 'Completed',
  [ETransferStatus.FAILED]: 'Failed',
};
