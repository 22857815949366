export enum EOrderStatus {
  ALL = 'All',
  WORKING = 'Working',
  PENDING = 'Pending',
  COMPLETED = 'Completed',
  CANCELLED = 'Cancelled',
  REJECTED = 'Rejected',
  SENDING = 'Sending',
  WAITING_FOR_PAYMENT = 'WaitingForPayment',
  EXPIRED = 'Expired',
}

export const ORDER_STATUS_LABELS_MAP: Record<EOrderStatus, string> = {
  [EOrderStatus.ALL]: 'All',
  [EOrderStatus.WORKING]: 'Working',
  [EOrderStatus.PENDING]: 'Pending',
  [EOrderStatus.COMPLETED]: 'Completed',
  [EOrderStatus.CANCELLED]: 'Cancelled',
  [EOrderStatus.REJECTED]: 'Rejected',
  [EOrderStatus.SENDING]: 'Sending',
  [EOrderStatus.WAITING_FOR_PAYMENT]: 'Waiting for payment',
  [EOrderStatus.EXPIRED]: 'Expired',
};
