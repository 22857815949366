import React from 'react';

import type IReactAria from 'react-aria';
import type IReactStately from 'react-stately';

interface ITooltipContext {
  state: IReactStately.TooltipTriggerState;
  triggerRef: React.MutableRefObject<HTMLSpanElement>;
  triggerProps: IReactAria.TooltipTriggerAria['triggerProps'];
  tooltipProps: IReactAria.AriaTooltipProps;
  overlayRef: React.MutableRefObject<HTMLDivElement>;
  overlayProps: IReactAria.PositionAria['overlayProps'];
  /**
   * The placement of the element with respect to its anchor element.
   * @default 'top'
   */
  placement?: IReactAria.PlacementAxis;
}

export const TooltipContext = React.createContext<ITooltipContext>({
  state: undefined,
  triggerRef: undefined,
  triggerProps: undefined,
  tooltipProps: undefined,
  overlayRef: undefined,
  overlayProps: undefined,
  placement: 'top',
});
