import { useState } from 'react';
import clsx from 'clsx';

import style from './style.module.scss';

interface IProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  name: string;
  width?: number;
  className?: string;
  onError?: () => void;
}

export const CoinIcon = ({ name, width, className = '', onError, ...rest }: IProps) => {
  const [imageError, setImageError] = useState(false);

  const handleError = () => {
    setImageError(true);
    onError && onError();
  };

  return (
    <>
      {!imageError && (
        <img
          src={`/img/icon/${name.toUpperCase()}.svg`}
          onError={handleError}
          className={clsx(style['coin-icon'], className)}
          alt={name}
          width={width}
          {...rest}
        />
      )}
      {imageError && <span className={clsx(style['text-substitute'], className)}>{name}</span>}
    </>
  );
};
