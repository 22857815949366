import { RefObject, forwardRef, useRef } from 'react';
import clsx from 'clsx';

import style from './style.module.scss';

export interface IPingInput extends React.InputHTMLAttributes<HTMLInputElement> {
  className?: string;
  disallowedKeysList?: string[];
  Icon?: React.ReactNode;
}

export const PingInput = forwardRef(
  ({ className = '', disallowedKeysList, Icon, ...rest }: IPingInput, ref: RefObject<HTMLInputElement>) => {
    const disallowedKeys = new Set(disallowedKeysList ?? []);
    const inputRef = useRef<HTMLInputElement>();

    return (
      <div className={clsx(style['input-wrapper'], className)} onClick={() => inputRef.current?.focus()}>
        {Icon && <div className={style['icon']}>{Icon}</div>}
        <input
          ref={ref || inputRef}
          onKeyDownCapture={event => {
            if (disallowedKeys.has(event.key)) {
              event.preventDefault();
            }
          }}
          {...rest}
        />
      </div>
    );
  }
);
