import HelpIcon from '@ping/assets/Icon/help.svg';
import { useState } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import type { ReactElement, ReactNode } from 'react';

interface ITooltipBasicProps {
  position: 'top' | 'right' | 'bottom' | 'left';
  childrenIcon?: ReactElement;
  children: ReactNode;
  contentClassName?: string;
  className?: string;
  showTooltip?: boolean;
}

export const TooltipBasic = ({
  children,
  childrenIcon = <HelpIcon />,
  position,
  contentClassName = '',
  className = '',
  showTooltip,
}: ITooltipBasicProps) => {
  const [show, setShow] = useState<boolean>(false);

  const handleOnMouseEnter = () => {
    setShow(true);
  };

  const handleOnMouseLeave = () => {
    setShow(false);
  };

  return (
    <OverlayTrigger
      show={show || showTooltip}
      key={position}
      placement={position}
      overlay={
        <Tooltip
          onMouseEnter={handleOnMouseEnter}
          onMouseLeave={handleOnMouseLeave}
          id={`tooltip-${position}`}
          className={contentClassName}
        >
          {children}
        </Tooltip>
      }
    >
      <span className={className} onMouseEnter={handleOnMouseEnter} onMouseLeave={handleOnMouseLeave}>
        {childrenIcon}
      </span>
    </OverlayTrigger>
  );
};
