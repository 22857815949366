export enum EMarketStatus {
  OPEN = 'Open',
  HALTED = 'Halted',
  PAUSED = 'Paused',
}

export const MARKET_STATUS_LABELS_MAP: Record<EMarketStatus, string> = {
  [EMarketStatus.OPEN]: 'Open',
  [EMarketStatus.HALTED]: 'Halted',
  [EMarketStatus.PAUSED]: 'Paused',
};
