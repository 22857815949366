import { clsx } from 'clsx';
import React from 'react';

import { TooltipContext } from '../tooltip.context';

import style from './style.module.scss';

interface ITooltipTriggerProps extends ICustomizable {
  children: React.ReactNode;
}

export const TooltipTrigger = (props: ITooltipTriggerProps) => {
  const context = React.useContext(TooltipContext);

  return (
    <span
      ref={context.triggerRef}
      {...context.triggerProps}
      className={clsx(style['tooltip-trigger'], props.className)}
    >
      {props.children}
    </span>
  );
};
