import { CancelReason } from '@ping/api';
import HelpIcon from '@ping/assets/Icon/help.svg';
import { Tooltip } from '@ping/uikit';
import { TRANSFER_STATUS_LABELS_MAP } from '@ping/enums/transfer-status.enum';
import { ORDER_STATUS_LABELS_MAP } from '@ping/enums/order-status.enum';
import { USER_STATUS_LABELS_MAP } from '@ping/enums/user-status.enum';
import { MARKET_STATUS_LABELS_MAP } from '@ping/enums/market-status.enum';
import clsx from 'clsx';

import style from './style.module.scss';

const CANCEL_REASON = {
  CancelledByAdmin: 'The order was cancelled due to a violation of the Terms of Service.',
  CancelledByTrader: 'This order has been cancelled by the trader.',
  CancelledBySystem: 'This order has been cancelled by the system.',
  SelfMatchPrevention: 'This order has been cancelled due to it matching your previous counter order.',
  NoMoreMatchesAvailable: 'This order has been cancelled due to no more matches available.',
};

interface IndicatorProps {
  status: string;
  red?: string | string[];
  yellow?: string | string[];
  green?: string | string[];
  gray?: string | string[];
  blue?: string | string[];
}

interface IProps extends IndicatorProps {
  hasTooltip?: boolean;
  hasIndicatorOnly?: boolean;
  hint?: string;
}

const Indicator = ({ status, red, yellow, green, gray, blue }: IndicatorProps) => {
  const isConsideredStatus = (value, statusType): boolean => {
    if (statusType) {
      if (typeof statusType === 'string') {
        return value === statusType;
      }
      return statusType.includes(value);
    }
    return false;
  };

  return (
    <i
      className={clsx(style['status-indicator'], {
        [style['bg--green']]: isConsideredStatus(status, green),
        [style['bg--red']]: isConsideredStatus(status, red),
        [style['bg--yellow']]: isConsideredStatus(status, yellow),
        [style['bg--gray']]: isConsideredStatus(status, gray),
        [style['bg--blue']]: isConsideredStatus(status, blue),
      })}
    />
  );
};

const StatusIndicatorContent = ({ ...rest }: IndicatorProps) => {
  const allStatusIndicator = {
    ...ORDER_STATUS_LABELS_MAP,
    ...TRANSFER_STATUS_LABELS_MAP,
    ...USER_STATUS_LABELS_MAP,
    ...MARKET_STATUS_LABELS_MAP,
  };

  return (
    <>
      <Indicator {...rest} />
      <div className={style['status-text']}>{allStatusIndicator[rest.status]}</div>
    </>
  );
};

export const StatusIndicator = ({ hasTooltip = false, hasIndicatorOnly = false, hint, ...rest }: IProps) => {
  if (hint && hint !== CancelReason.NotCancelled) {
    return (
      <div className={style['status-tooltip-wrapper']}>
        <StatusIndicatorContent {...rest} />
        <Tooltip
          position='top'
          childrenIcon={
            <span className={style['help-icon-tooltip']}>
              <HelpIcon />
            </span>
          }
        >
          {CANCEL_REASON[hint]}
        </Tooltip>
      </div>
    );
  }

  if (hasIndicatorOnly) {
    return <Indicator {...rest} />;
  }

  if (hasTooltip) {
    return (
      <Tooltip
        position='top'
        childrenIcon={
          <span className={style['status-indicator-wrapper']}>
            <Indicator {...rest} />
          </span>
        }
        contentClassName={style['status-tooltip-wrapper']}
      >
        <StatusIndicatorContent {...rest} />
      </Tooltip>
    );
  }

  return (
    <div className={style['status-tooltip-wrapper']}>
      <StatusIndicatorContent {...rest} />
    </div>
  );
};
