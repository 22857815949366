export enum EUserStatus {
  ALL = 'All',
  ACTIVE = 'Active',
  FROZEN = 'Frozen',
  TERMINATED = 'Terminated',
  Banned = 'Banned',
  WaitingForData = 'WaitingForData',
  WaitingForReview = 'WaitingForReview',
  MUTE = 'Mute',
  LIMITED = 'Limited',
}

export const USER_STATUS_LABELS_MAP: Record<EUserStatus, string> = {
  [EUserStatus.ALL]: 'All',
  [EUserStatus.ACTIVE]: 'Active',
  [EUserStatus.FROZEN]: 'Frozen',
  [EUserStatus.TERMINATED]: 'Terminated',
  [EUserStatus.Banned]: 'Banned',
  [EUserStatus.WaitingForData]: 'Waiting for data',
  [EUserStatus.WaitingForReview]: 'Waiting for review',
  [EUserStatus.MUTE]: 'Mute',
  [EUserStatus.LIMITED]: 'Limited',
};
